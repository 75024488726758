
import { Component, Emit, Inject, Model, Prop, Provide, Vue, Watch } from 'vue-property-decorator';
import BackButton from '@/views/module/common/BackButton.vue';


import UserQuery from '@/app/com/main/module/business/user/data/UserQuery';
import Page from '@/app/com/common/data/Page';
import app from '@/app/App';
import UserInfoController from '@/app/com/main/module/business/user/controller/UserInfoController';
import User from '@/app/com/main/module/business/user/bean/User';
import DataBackAction from '@/app/base/net/DataBackAction';
import Prompt from '@/platform/wap/common/Prompt';
import UserInfoUtil from '@/app/com/main/common/util/UserInfoUtil';

import ContactAddApply from '@/views/module/contact/apply/ContactAddApply.vue';

@Component({
    components: {
        BackButton,
        ContactAddApply,
    },
})
export default class UserQueryPane extends Vue {
    private query: UserQuery = new UserQuery();
    private page: Page = new Page();
    private list: User[] = [];

    private items: User[] = [];

    private handlePage(value: number): void {
        const own = this;
        own.page.number = value;
        this.queryList();
    }

    private handlePageSize(value: number): void {
        const own = this;
        own.page.size = value;
        this.queryList();
    }

    private handleSearch(): void {
        const own = this;
        own.page.number = 1;
        this.queryList();
    }

    private queryList(): void {

        const own = this;
        const back: DataBackAction = {
            back(data: any): void {
                // console.log('搜索数据', data)
                if (data) {
                    const info = data.info;
                    if (info) {
                        if (info.success && data.body) {
                            const list: User[] = data.body.items;
                            const p: Page = data.body.page;
                            own.setList(list, p);
                        }
                    }
                }
            },
            lost(data: any): void {
                Prompt.notice('请求失败！');
            },
            timeOut(data: any): void {
                Prompt.notice('请求超时！');
            },
        } as DataBackAction;

        const query: UserQuery = this.query;
        const page: Page = this.page;
        const uc: UserInfoController = app.appContext.getMaterial(UserInfoController);
        uc.queryUserList(query, page, back);
    }

    private setList(list: User[], page: Page) {
        if (!list) {
            list = [];
        }
        if (page) {
            const totalCount = page.totalCount;
            this.page.totalCount = totalCount;
            this.page.totalPage = page.totalPage;
        }
        for (const user of list) {
            UserInfoUtil.handleAvatar(user);
        }
        this.list = list;
    }


    private handleAddUser(userId: string) {
        const addUserViewName = 'addUserView';
        const addUserView: any = this.$refs[addUserViewName];
        addUserView.setUserId(userId);
        addUserView.setShow(true);
    }

    public mounted() {
        // this.page.setSize(5);
        // if (this.items.length > 0) {
        //     return;
        // }
        // const names: string[] = ['网', 'xk', '和', '在', '他', '0', '我', '啊', '么', '了', '来',];
        // const l = names.length;
        // let j = 1;
        // for (let i = 0; i < 100; i++) {
        //     const index = Math.round(Math.random() * l);
        //     if (j > 29) {
        //         j = 1;
        //     }
        //     const data: User = new User();
        //     data.id = i + '';
        //     data.signature = '这是地' + i + '个';
        //     data.nickname = names[index] + '牛B' + i + '号';
        //     data.avatar = 'assets/images/common/head/user/' + j + '.png';
        //     j++;
        //     this.items.push(data);
        // }
    }
}
