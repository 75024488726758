
    import {Component, Emit, Inject, Model, Prop, Provide, Vue, Watch} from 'vue-property-decorator';
    import BackButton from '@/views/module/common/BackButton.vue';
    import UserQueryPane from '@/views/module/user/find/UserFindPane.vue';
    import GroupQueryPane from '@/views/module/group/find/GroupFindPane.vue';

    @Component({
        components: {
            BackButton,
            UserQueryPane,
            GroupQueryPane,
        },
    })
    export default class QueryPane extends Vue {
        private tab: string = '';
    }
